import { noop, debounce, invoke } from 'lodash';

const trackers = {};
const noopTracker = { trackStart: noop, trackEnd: noop, trackPromise: (_, fn) => fn(), performanceEntries: {} };
const performanceNow = () => performance.now();

export const createPerformanceTracker = (scope, { isDebug, isSSR } = {}, now = performanceNow) => {
  if (!scope || !isDebug || isSSR || typeof performance === 'undefined') {
    return noopTracker;
  }

  if (!trackers[scope]) {
    const perfSubEntries = {};
    const perfParents = {};
    const tracker = {
      performanceEntries: {},
      trackStart: (marker, parentMarker) => {
        if (parentMarker) {
          perfParents[marker] = parentMarker;
        }
        const entries = parentMarker
          ? (perfSubEntries[parentMarker] = perfSubEntries[parentMarker] || {})
          : tracker.performanceEntries;
        entries[marker] = now();
        return marker;
      },
      trackEnd: (marker, subValues = []) => {
        if (perfSubEntries[marker]) {
          subValues = Object.entries(perfSubEntries[marker])
            .map(([key, props]) => ({ key, ...props }))
            .concat(subValues);
          delete perfSubEntries[marker];
        }
        const parentMarker = perfParents[marker];
        const entries = parentMarker ? perfSubEntries[parentMarker] : tracker.performanceEntries;
        const value = now() - entries[marker];
        entries[marker] = Array.isArray(subValues) && subValues.length ? { value, subValues } : { value };
      },
      trackPromise: async (marker, promiseFn, parentMarker) => {
        tracker.trackStart(marker, parentMarker);
        const result = await promiseFn();
        tracker.trackEnd(marker);
        return result;
      },
      reset: () => {
        tracker.performanceEntries = {};
      },
    };
    trackers[scope] = tracker;
  }

  return trackers[scope];
};

export const getMetrics = (scope) =>
  scope
    ? trackers[scope].performanceEntries
    : Object.keys(trackers).reduce((acc, x) => {
        acc[x] = trackers[x].performanceEntries;
        return acc;
      }, {});

let _shouldLog = true;

export const resetMetrics = (scope) => {
  _shouldLog = true;
  invoke(trackers[scope], 'reset');
};

export const logMetrics = debounce(() => {
  _shouldLog && console.log('Metrics', getMetrics());
  _shouldLog = false;
}, 5000);
