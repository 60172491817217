import { trim, trimEnd } from 'lodash';
import memoizeOne from 'memoize-one';
import { SECTION_BLOG_PAGE, numberToPage, createPageUrl } from '@wix/communities-blog-client-common';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';

import Wix from './wix-sdk-polyfill';

export const createArchivePageUrl = (sectionUrl, year, month, page) =>
  createPageUrl(page, `${trimEnd(sectionUrl, '/')}/archive/${year}/${month}`);

export const handleNavigation = memoizeOne(
  (path, isSite) => ({ event, page, pageUrl, navigateInPreview, isExperimentProdOOIEditorEnabled }) => {
    if (!isSite) {
      event.preventDefault();
      if (isExperimentProdOOIEditorEnabled) {
        Wix.Utils.navigateToSection(
          {
            appDefinitionId: BLOG_APP_ID,
            sectionId: SECTION_BLOG_PAGE,
            shouldRefreshIframe: false,
          },
          `${trim(path, '/')}${numberToPage(page, path.endsWith('/'))}`,
        );
      } else {
        navigateInPreview(pageUrl);
      }
    }
  },
);
