import React from 'react';
import { get, memoize } from 'lodash';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { withStyles as _withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_MEMOIZE_STYLES } from '@wix/communities-blog-experiments';

const isMemoizeStylesEnabled = (props) =>
  isExperimentEnabled({ experiments: get(props, 'state.experiments', {}) }, EXPERIMENT_MEMOIZE_STYLES);

export const withStyles = (Component, options) => {
  class WithStyles extends React.Component {
    static displayName = `withStyles(${getDisplayName(Component)})`;

    Component = _withStyles(Component, {
      ...options,
      enableMemoization:
        isMemoizeStylesEnabled(this.props) && (typeof WeakMap === 'undefined' || memoize.Cache !== WeakMap),
    });

    render() {
      return (
        // In Thunderbolt, in Dev mode, SSR fails and outputs the following:
        // <div data-reactroot></div>
        // Afterwards, React.hydrate does not apply a class attribute to the element
        // ``
        //    There are no guarantees that attribute differences will be
        //    patched up in case of mismatches [https://reactjs.org/docs/react-dom.html#hydrate]
        // ``
        // withStyles HOC is wrapped with another <div> to avoid this situation.
        // After the hydration finishes the HTML looks as follows:
        // <div data-reactroot>
        //   <div class=".[componentId]">
        //     ..
        //   </div>
        // </div>
        <div>
          <this.Component {...this.props} />
        </div>
      );
    }
  }

  hoistNonReactStatics(WithStyles, Component);

  return WithStyles;
};
