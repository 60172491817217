import { isInteger, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Img } from '@wix/communities-image-lib';
import { EXPERIMENT_LOAD_IMAGES_LAZILY } from '@wix/communities-blog-experiments';

import withExperiment from '../../hoc/with-experiment';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import { getMobileScaleFactor, getMobileViewportHeight } from '../../services/viewport';
import OnScreen from '../on-screen';
import styles from './post-list-item-image.scss';

export const PostListItemImageMobile = ({
  image,
  url,
  isPublic,
  rootWidth,
  width,
  isLoadImagesLazilyExperimentEnabled,
}) => {
  const props = isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true };
  const scaleFactor = getMobileScaleFactor();
  const imageWidth = Math.floor(width ? width * scaleFactor : rootWidth * scaleFactor);
  return (
    <div className={classNames(styles.container, styles.mobile)}>
      {isLoadImagesLazilyExperimentEnabled ? (
        <OnScreen threshold={getMobileViewportHeight()}>
          {({ isOnScreen, setRef }) => (
            <Img
              src={image}
              width={isInteger(imageWidth) ? imageWidth : undefined}
              className={styles.image}
              ref={setRef}
              isOnScreen={isOnScreen}
              {...props}
            />
          )}
        </OnScreen>
      ) : (
        <Img src={image} width={isInteger(imageWidth) ? imageWidth : undefined} className={styles.image} {...props} />
      )}
    </div>
  );
};

PostListItemImageMobile.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  url: PropTypes.string,
  isPublic: PropTypes.bool,
  rootWidth: PropTypes.number,
  width: PropTypes.number,
  isLoadImagesLazilyExperimentEnabled: PropTypes.bool,
};

export default flowRight(
  withResponsiveContext,
  withExperiment({ isLoadImagesLazilyExperimentEnabled: EXPERIMENT_LOAD_IMAGES_LAZILY }),
)(PostListItemImageMobile);
