import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

import { ARCHIVE_POSTS_PER_PAGE } from '@wix/communities-blog-client-common';
import PostListItem from '../../../search/components/post-search-list-item';
import Pagination from '../../../common/containers/pagination';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import styles from './archive-posts-list.scss';
import { createArchivePageUrl } from '../../../common/services/navigation';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';

const ArchivePostsDesktopList = ({
  params: { year, month, pageIndex },
  posts,
  entityCount,
  onPostLikeClick,
  borderWidth,
  sectionUrl,
}) => {
  const style = {
    borderWidth,
    marginTop: -borderWidth,
  };
  return (
    <div className={classNames(styles.container, 'search-results-list')}>
      <div className={classNames(styles.wrapper, 'blog-card-border-color')} style={style}>
        {posts.map((post) => (
          <PostListItem key={post._id} post={post} onLikeClick={onPostLikeClick} />
        ))}
        <Pagination
          page={Number(pageIndex || 1)}
          pageSize={ARCHIVE_POSTS_PER_PAGE}
          entityCount={entityCount}
          createPageUrl={(page) => createArchivePageUrl(sectionUrl, year, month, page)}
        />
      </div>
    </div>
  );
};

ArchivePostsDesktopList.propTypes = {
  posts: PropTypes.array.isRequired,
  entityCount: PropTypes.number.isRequired,
  borderWidth: PropTypes.number.isRequired,
  onPostLikeClick: PropTypes.func,
  params: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  onPostLikeClick: actions.incrementPostLikeCount,
  sectionUrl: getSectionUrl(state),
});

export default connect(mapRuntimeToProps)(withCardBorderWidth(ArchivePostsDesktopList));
