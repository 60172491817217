import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import DotDotDot from '../dotdotdot';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import { getFeedColorClassName } from '../../services/layout-config';
import styles from './post-list-item-pro-gallery-title.scss';

export const PostListItemProGalleryTitle = ({
  style,
  className,
  getPostClassName,
  layoutName,
  lineCount,
  title,
  type,
}) => {
  const titleClassName = classNames(
    styles.title,
    styles[type],
    className,
    'post-title',
    'blog-hover-container-element-color',
    getPostClassName(getFeedColorClassName(layoutName, 'title-color'), 'title-font'),
  );

  return (
    <h2 className={titleClassName} style={style} data-hook="post-title">
      <DotDotDot useExactLineHeight={true} clamp="auto" maxLineCount={lineCount} className={className}>
        {title}
      </DotDotDot>
    </h2>
  );
};

PostListItemProGalleryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  lineCount: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  layoutName: PropTypes.string,
  getPostClassName: PropTypes.func,
};

export default flowRight(withLayoutColorClasses, withIsFeedDesignEnabled)(PostListItemProGalleryTitle);
