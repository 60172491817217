import { get } from 'lodash';
import { isSSR } from '@wix/communities-blog-client-common';
import { isInWix } from './is-in-wix';

export const getMobileScaleFactor = () => {
  if (isSSR() || isInWix()) {
    return 1;
  }
  const defaultMobileVieportWidth = 320;
  const defaultMobileScreenWidth = 320;

  const screenWidth = get(window, 'screen.width', defaultMobileScreenWidth);
  const viewportWidth = get(window, 'innerWidth', defaultMobileVieportWidth);
  return screenWidth / viewportWidth;
};

export const getMobileViewportHeight = () => {
  const defaultHeight = 640;
  if (isSSR()) {
    return defaultHeight;
  }

  return get(window, 'innerHeight', defaultHeight);
};
